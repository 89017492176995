.meet-greet-card-gallery-card {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.meet-greet-card-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-top-right-radius: var(--dl-radius-radius-radius8);
}
.meet-greet-card-container {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.meet-greet-card-text {
  color: var(--dl-color-primary-700);
  font-size: 1.8rem;
  align-self: flex-start;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
}
.meet-greet-card-text1 {
  color: var(--dl-color-primary-700);
  font-size: 22px;
  align-self: flex-start;
  margin-top: 4px;
  margin-bottom: 6ox;
}
.meet-greet-card-text2 {
  color: var(--dl-color-primary-700);
  font-size: 22px;
  align-self: flex-start;
  font-style: italic;
  margin-bottom: var(--dl-space-space-unit);
}


















