.timetable-row4-row {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.timetable-row4-image {
  color: var(--dl-color-primary-700);
  padding: 4px;
}
.timetable-row4-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.timetable-row4-container1 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
}
.timetable-row4-container2 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
}
.timetable-row4-link {
  text-decoration: none;
}
.timetable-row4-link1 {
  text-decoration: none;
}
.timetable-row4-link2 {
  text-decoration: none;
}
.timetable-row4-link3 {
  text-decoration: none;
}






