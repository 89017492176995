.timetable-row1-row {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.timetable-row1-image {
  color: var(--dl-color-primary-700);
  padding: 4px;
}
.timetable-row1-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.timetable-row1-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.timetable-row1-link {
  text-decoration: none;
}













@media(max-width: 479px) {
  .timetable-row1-container1 {
    flex-wrap: wrap;
  }
}
